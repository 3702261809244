import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Main',
    meta: { title: 'Software development for your business - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Main.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { title: 'Contact - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Contact.vue')
  },
  {
    path: '/about',
    name: 'Company',
    meta: { title: 'Learn more - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Company.vue')
  },
  {
    path: '/services',
    name: 'Services',
    meta: { title: 'Our services - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Services.vue')
  },
  {
    path: '/technology',
    name: 'Technology',
    meta: { title: 'Technology - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Technology.vue')
  },
  // {
  //   path: '/blog',
  //   name: 'Blog',
  //   meta: { title: 'Blog software house - Complaia Systems' },
  //   component: () => import(/* webpackChunkName: "Login" */ '../views/Blog.vue')
  // },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/PageNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

